import { Navigate, Outlet } from "react-router-dom";
import { isAutheticated } from "../component/common/helpers/api";

const AdminRouter = () => {

  if (!isAutheticated()) {
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
};

export {AdminRouter}