import { Home } from "./component/common/Home";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { Signup } from "./component/common/Signup";
import { Signin } from "./component/common/Signin";
import { Dashboard } from "./component/admin/Dashboard";
import { AdminRouter } from "./router/AdminRouter";
import { ViewLeads } from "./component/admin/ViewLeads";
import { UploadLeads } from "./component/admin/UploadLeads";
import { CreateTeam } from "./component/admin/CreateTeam";


const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Signin />} />

      <Route element={<AdminRouter />}>
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/viewleads" element={<ViewLeads />} />
        <Route path="/admin/uploadleads" element={<UploadLeads />} />
        <Route path="/admin/createteam" element={<CreateTeam />} />
      </Route>

    </Routes>
  );
}

const router = createBrowserRouter([
  { path: "*", Component: Root },
]);

export default function App() {
  return <RouterProvider router={router} />;
}


