import { Link, useNavigate } from "react-router-dom"
import { Base } from "../Base"
import { authenticate, createteam, signinapi, userpresale, usersale } from "./helpers/api"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { isAdmin } from "../common/helpers/api"

const CreateTeamComp = () => {
    const navigate = useNavigate();

    // const showSuccessMessage = (message) => {
    //     toast.success(" 🦄 "+message, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //     });
    // }

    const showErrorMessage = (message = "Error") => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
        });
    }


    

    const [sale, setSale] = useState([]);

    const [presale, setPresale] = useState([]);


    const [team, setTeam] = useState({
        teamname: "",
        presales: [],
        sales: [],

    });

    const { teamname, presales, sales } = team;

    const handelonChange = (name_of_field, event) => {
        const value = event.target.value;
    
        setTeam(prevTeam => {
            if (name_of_field === 'teamname') {
                return { ...prevTeam, teamname: value };
            }
    
            // Initialize the field as an empty array if undefined
            const currentField = Array.isArray(prevTeam[name_of_field]) ? prevTeam[name_of_field] : [];
    
            // Toggle the value: if it exists, remove it; if not, add it
            const updatedField = currentField.includes(value)
                ? currentField.filter(item => item !== value)
                : [...currentField, value];
    
            return {
                ...prevTeam,
                [name_of_field]: updatedField,
            };
        });
    };

    let publicUrl = process.env.PUBLIC_URL + '/'

    useEffect(() => {
        const user = isAdmin();
        usersale('sale', user.data.token)
            .then(response => {
                setSale(response)
                console.log(response);
            })
            .catch(error => {
                // Handle the error
                console.log(error);
            });

        userpresale('presale', user.data.token)
            .then(response => {
                setPresale(response)
                console.log(response);
            })
            .catch(error => {
                // Handle the error
                console.log(error);
            });
    }, []);

    const submit = () => {
        createteam(team)
            .then(res => {
                console.log(res)
                // if (res.status === 'success') {
                //     authenticate(res,
                //         navigate("/admin/dashboard")
                //     )
                // }
                // showErrorMessage()

            })
            .catch((e) => {
                showErrorMessage(e.message)
            })
    }

    return (
        < div >
            <div className="ltn__login-area pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area text-center">
                                <h1 className="section-title trible">Create Team
                                </h1>
                                <lord-icon
                                    src="https://cdn.lordicon.com/xzalkbkz.json"
                                    trigger="in"
                                    // delay="1500"
                                    state="in-reveal"
                                    colors="primary:#000000,secondary:#000000"
                                    style={{ width: "170px", height: "170px" }}>
                                </lord-icon>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {JSON.stringify(team)}

                            <div className="account-login-inner">
                                <input type="text" value={teamname} placeholder="Team Name" onChange={(e) => handelonChange('teamname', e)} />
                                <div className="account-login-inner">
                                    <label>Select Sale:</label>
                                    {sale.map(option => (
                                        <div key={option._id}>
                                            <input
                                                type="checkbox"
                                                value={option._id}
                                                onChange={(e) => handelonChange('sales', e)}
                                            />
                                            {option.first_name}
                                        </div>
                                    ))}
                                </div>
                                <div className="btn-wrapper mt-0">
                                    <button className="theme-btn-1 btn btn-block" id="submitbutton" type="submit" onClick={() => submit()}>Create</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="account-login-inner">
                                <label>Select Presale:</label>
                                {presale.map(option => (
                                    <div key={option._id}>
                                        <input
                                            type="checkbox"
                                            value={option._id}
                                            onChange={(e) => handelonChange('presales', e)}
                                        />
                                        {option.first_name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
                <div className="modal fade" id="ltn_forget_password_modal" tabIndex={-1}>
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="ltn__quick-view-modal-inner">
                                    <div className="modal-product-item">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="modal-product-info text-center">
                                                    <h4>FORGET PASSWORD?</h4>
                                                    <p className="added-cart"> Enter you register email.</p>
                                                    <form action="#" className="ltn__form-box">
                                                        <input type="text" name="email" placeholder="Type your register email*" />
                                                        <div className="btn-wrapper mt-0">
                                                            <button className="theme-btn-1 btn btn-full-width-2" type="submit">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* additional-info */}
                                                <div className="additional-info d-none">
                                                    <p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
                                                    <div className="payment-method">
                                                        <img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

const CreateTeam = () => {
    return (
        <Base>
            {CreateTeamComp()}
        </Base>
    )
}

export { CreateTeam }