import { NavBar } from '../component/common/NavBar';
import { ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const Base = (props) => {
    return (
        <div>
            <NavBar />
            <ToastContainer />
            {props.children}
        </div>
    )
}