import { Link } from "react-router-dom"

const { Base } = require("../Base")
const Home = () => {
    return (
        <Base>
            <div className="ltn__404-area ltn__404-area-1 mb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error-404-inner text-center">
                                <div className="error-img mb-30">
                                    <lord-icon
                                        src="https://cdn.lordicon.com/yvrtgyqf.json"
                                        trigger="loop"
                                        
                                        style={{ width: "250px", height: "250px" }}>
                                    </lord-icon>
                                </div>
                                <h1 className="error-404-title d-none">Under Development</h1>
                                <h2>I am Working on it!</h2>
                                {/* <h3>Oops! Looks like something going rong</h3> */}
                                <p>Meanwhile Try Signing In</p>
                                <div className="btn-wrapper go-top">
                                    <Link to="/signin" className="btn btn-transparent"><i className="fas fa-long-arrow-alt-right" /> SIGN IN ?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )
}
export { Home }