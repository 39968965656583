import axios from "axios"

const url = 'https://khsekmlwkh.execute-api.ap-south-1.amazonaws.com/users'
// const url = 'http://localhost:3000/users'

const signinapi = (data) => {
    return axios.post(`${url}/signin`, {
        email: data.email,
        password: data.password
    }, {
        headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
        }
    })
        .then((response) => {
            console.log(response);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Invalid Credentials")
        });
}

const signupapi = (data) => {
    return axios.post(`${url}/signup`, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        role: data.role
    })
        .then((response) => {
            console.log(response);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Internal Server Error")
        });
}

const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("user", JSON.stringify(data));
        next();
    }
};

const isAutheticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
    } else {
        return false;
    }
};

const isAdmin = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.data.role === 'admin') {
            return user
        }
    } else {
        return false;
    }
};

export { signinapi, signupapi, authenticate, isAutheticated, isAdmin }
