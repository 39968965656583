import { Base } from "../Base"
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { useEffect, useMemo, useState } from "react";
import { leadsgetall } from "./helpers/api";
import { isAdmin, isAutheticated } from "../common/helpers/api";

const ViewLeads = () => {

    const [rowData, setRowData] = useState([
        
    ]);

    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        {
            field: '_id',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
          },
        { field: "__v" },
        { field: "lead_id" },
        { field: "created_time" },
        { field: "ad_id" },
        { field: "ad_name" },
        { field: "adset_id" },
        { field: "adset_name" },
        { field: "campaign_id" },
        { field: "campaign_name" },
        { field: "form_id" },
        { field: "form_name" },
        { field: "is_organic" },
        { field: "platform" },
        { field: "apartment_type" },
        { field: "full_name" },
        { field: "phone_number" },
        { field: "email" },
    ]);

    useEffect(() => {
        if (isAutheticated()) {
            const user = isAdmin();
            const fetchData = async () => {
                const leads = await leadsgetall(user);
                setRowData(leads);
            }
            fetchData()
        }
    }, [])


    return (
        <Base>
            < div >
                <div className="ltn__login-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <div className="ag-theme-quartz" style={{ height: 500 }}>
                                        <AgGridReact rowData={rowData} columnDefs={colDefs} rowSelection={'multiple'} suppressRowClickSelection={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Base>
    )
}

export { ViewLeads }