import axios from "axios"

const url = 'https://khsekmlwkh.execute-api.ap-south-1.amazonaws.com/users'
// const url = 'http://localhost:3001/leads'
const userurl = 'http://localhost:3000/users'
const teamurl = 'http://localhost:3000/team'

const leadsgetall = (user) => {
    return axios.get(`${url}/get_all`, {
        headers: { Authorization: user.data.token }
    })
        .then((response) => {
            console.log(response.data.data.body);
            return response.data.data.body;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Invalid Credentials")
        });
}

const leadfileupload = (file, token) => {
    return axios.post(`${url}/upload`, file, {
        headers: { Authorization: token }
    })
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Invalid Credentials")
        });
}

const usersale = (role, token) => {
    return axios.get(`${userurl}/getbyrole?role=${role}`, {
        headers: { Authorization: token }
    })
        .then((response) => {
            console.log(response);
            return response.data.data.body;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Invalid Credentials")
        });
}

const userpresale = (role, token) => {
    return axios.get(`${userurl}/getbyrole?role=${role}`, {
        headers: { Authorization: token }
    })
        .then((response) => {
            console.log(response);
            return response.data.data.body;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Invalid Credentials")
        });
}

const createteam = (data, token) => {
    return axios.post(`${teamurl}/create`, {
        name: data.teamname,
        presale: data.presales,
        sales: data.sales,
    }, {
        headers: { Authorization: token }
    })
        .then((response) => {
            console.log(response);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw new Error("Internal Server Error")
        });
}

export { leadsgetall, leadfileupload, usersale, userpresale, createteam }