import { Base } from "../Base"

const Dashboard = () =>{
    return (
        <Base>
        
        </Base>
    )
}

export {Dashboard}