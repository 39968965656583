import { useState } from "react";
import { Base } from "../Base"
import { leadfileupload } from "./helpers/api";
import { isAdmin } from "../common/helpers/api";

const UploadLeads = () => {
    const [file, setFile] = useState();

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("file", file);

        const user = isAdmin();
        console.log(user.data.token)
        // You may need to pass user.data.token in your function call
        leadfileupload(formData, user.data.token)
            .then((result) => {
                // Handle the result as needed
                console.log(result);
            })
            .catch((error) => {
                // Handle errors
                console.error(error);
            });
    };

    return (
        <Base>
            < div >
                <div className="ltn__login-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1>UPLOAD LEADS FROM HERE!</h1>
                                   
                                </div>
                                <div className="section-title-area text-center">
                                    <input type="file" name="lead_file" id="lead_file" onChange={(e)=>handleFileUpload(e)}></input>
                                    <button  name="upload_btn" className="btn btn-small btn-primary" onClick={handleUpload}>Upload Lead</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Base>
    )
}

export { UploadLeads }