import { Link, useNavigate } from "react-router-dom"
import { Base } from "../Base"
import { authenticate, signinapi } from "./helpers/api"
import { useState } from "react"
import { toast } from "react-toastify"

const SigninComponent = () => {
    const navigate = useNavigate();

    // const showSuccessMessage = (message) => {
    //     toast.success(" 🦄 "+message, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //     });
    // }

    const showErrorMessage = (message="Error") => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
        });
    }


    const [user, setUser] = useState({
        email: "arbazb@gmail.com",
        password: "hello"
    });

    const { email, password } = user;

    const handelonChange = (name_of_field, event) => {
        setUser({ ...user, [name_of_field]: event.target.value })
    }

    let publicUrl = process.env.PUBLIC_URL + '/'

    const submit = () => {
        signinapi(user)
            .then(res => {
                if (res.status === 'success') {
                    authenticate(res,
                        navigate("/admin/dashboard")
                    )
                }
                showErrorMessage()

            })
            .catch((e) => {
                showErrorMessage(e.message)
            })
    }

    return (
        < div >
            <div className="ltn__login-area pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area text-center">
                                <h1 className="section-title trible">Sign In <br />Into Your Account
                                </h1>
                                <lord-icon
                                    src="https://cdn.lordicon.com/xzalkbkz.json"
                                    trigger="in"
                                    // delay="1500"
                                    state="in-reveal"
                                    colors="primary:#000000,secondary:#000000"
                                    style={{ width: "170px", height: "170px" }}>
                                </lord-icon>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="account-login-inner">
                                <input type="text" value={email} name="email" placeholder="Email*" onChange={(e) => handelonChange('email', e)} />
                                <input type="password" value={password} name="password" placeholder="Password*" onChange={(e) => handelonChange('password', e)} />
                                <div className="btn-wrapper mt-0">
                                    <button className="theme-btn-1 btn btn-block" id="submitbutton" type="submit" onClick={() => submit()}>SIGN IN</button>
                                </div>
                                <div className="go-to-btn mt-20">
                                    <a href="/" title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal"><small>FORGOTTEN YOUR PASSWORD?</small></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="account-create text-center pt-50">
                                <h4>DON'T HAVE AN ACCOUNT?</h4>
                                <div className="btn-wrapper go-top">
                                    <Link to="/signup" className="theme-btn-1 btn black-btn">SIGN UP</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
                <div className="modal fade" id="ltn_forget_password_modal" tabIndex={-1}>
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="ltn__quick-view-modal-inner">
                                    <div className="modal-product-item">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="modal-product-info text-center">
                                                    <h4>FORGET PASSWORD?</h4>
                                                    <p className="added-cart"> Enter you register email.</p>
                                                    <form action="#" className="ltn__form-box">
                                                        <input type="text" name="email" placeholder="Type your register email*" />
                                                        <div className="btn-wrapper mt-0">
                                                            <button className="theme-btn-1 btn btn-full-width-2" type="submit">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* additional-info */}
                                                <div className="additional-info d-none">
                                                    <p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
                                                    <div className="payment-method">
                                                        <img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

const Signin = () => {
    return (
        <Base>
            {SigninComponent()}
        </Base>
    )
}

export { Signin }